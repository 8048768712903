import React, { Fragment } from "react";
import WebcamCapture from "../WebcamCapture";
import SelfieImg from '../../assets/svg/selfie.svg';

const Selfie = ({ dispatch, handleOnClickNext }) => {
    return (
        <Fragment>
            <div className="w-full flex flex-col align-center justify-evenly flex-grow fade-in">
                <h1 className="block text-gray-700 -mt-4 mb-2 font-bold text-base md:text-lg">
                    Carga la selfie o fotografía
                </h1>
                <img src={SelfieImg} className="object-contain w-1/2 h-1/2 lg:w-1/4 self-center" alt="back document" />
                <WebcamCapture content="Carga la selfie" handleOnClickNext={handleOnClickNext} selfie={true} dispatch={dispatch} />
            </div>
        </Fragment>
    );
};

export default Selfie;
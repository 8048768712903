import React, { Fragment, useState, useReducer, useEffect } from "react";
import Stepper from "./Stepper";
import InitialInformation from "./steps/InitialInformation";
import FrontDocument from "./steps/FrontDocument";
import BackDocument from "./steps/BackDocument";
import SelfieVideo from "./steps/SelfieVideo";
import Selfie from './steps/Selfie';
import SelfieVideoDesktop from "./steps/SelfieVideoDesktop";
import CloseSVG from "../assets/images/close.svg";
import FinalStep from "./steps/FinalStep";
import BrandLogo from "../assets/images/TelefonicaLogo.png";
import {
  sendMessage,
  getDeviceType,
} from "./../Utils";
import DocumentType from "./steps/DocumentType";
import CountrySelector from "./steps/CountrySelector";

const complexStateInitial = {
  frontDocument: null,
  backDocument: null,
  selfieVideo: null,
  selfie: null,
  documentType: "national-id",
  country: null,
  specificDocumentType: ''
  // .. more states
};

const closeStylesError = {
  maxWidth: "25px",
  position: "absolute",
  alignSelf: "flex-start",
};

function reducer(state, action) {
  switch (action.type) {
    case "frontDocument":
      return { ...state, frontDocument: action.value };
    case "backDocument":
      return { ...state, backDocument: action.value };
    case "selfieVideo":
      return { ...state, selfieVideo: action.value };
    case "selfie":
      return { ...state, selfie: action.value };
    case "documentType":
      return { ...state, documentType: action.value };
    case "country":
      return { ...state, country: action.value };
    case "specificDocumentType":
      return { ...state, specificDocumentType: action.value };

    // ... more states
    default:
      throw new Error();
  }
}

const StepperForm = (props) => {
  const [state, dispatch] = useReducer(reducer, complexStateInitial);
  const [activeStep, setActiveStep] = useState(1);
  const [frontImageDoc, setFrontImageDoc] = useState([]);
  const [video, setVideo] = useState([]);
  const [deviceType, setDeviceType] = useState();
  const [openFromIframe, setOpenFromIframe] = useState();
  const steps = [
    { title: "Intro" },
    { title: "País del documento" },
    { title: "Tipo de Documento" },
    { title: "Documento Frontal" },
    { title: "Documento Posterior" },
    { title: "Video de validación" },
    { title: "Foto de validación" },
    { title: "Final" },
  ];

  if (!props.canVideo || props.liveness === 'passive') {
    const videoIndex = steps.map(e => e.title).indexOf("Video de validación");
    steps.splice(videoIndex, 1);
  }

  if (!props.canVideo || props.liveness === 'active') {
    const fotografiaIndex = steps.map(e => e.title).indexOf("Foto de validación");
    steps.splice(fotografiaIndex, 1);
  }

  if (props.country) {
    let countryIndex = steps.map((e) => e.title).indexOf("País del documento");
    steps.splice(countryIndex, 1);
  }

  if (state.documentType === "passport") {
    const backDocIndex = steps.map((e) => e.title).indexOf("Documento Posterior");
    steps.splice(backDocIndex, 1);
  }

  const handleOnClickNext = () => {
    let nextStep = activeStep + 1;
    setActiveStep(nextStep);
  };

  useEffect(() => {
    const deviceType = getDeviceType();
    window === window.parent
      ? setOpenFromIframe(false)
      : setOpenFromIframe(true);
    setDeviceType(deviceType);
  }, []);

  const closePopUp = (e) => {
    e.preventDefault();

    sendMessage("exitedSdk", { data: "exited manually by user" });
  };

  const onChangeUseReducer = (e) => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
  };

  useEffect(() => {
    const deviceType = getDeviceType();
    window === window.parent
      ? setOpenFromIframe(false)
      : setOpenFromIframe(true);
    setDeviceType(deviceType);
  }, []);

  return (
    <Fragment>
      <div className="flex items-center justify-center w-full md:w-3/5 lg:w-1/2 p-2 md:p-6 h-full max-h-full">
        <div className="min-w-full w-full h-full lg:w-1/2 relative">
          {openFromIframe ? (
            <button onClick={closePopUp} className="close-frame absolute top-0 right-0 m-4">
              {" "}
              <img src={CloseSVG} alt="Close"></img>
            </button>
          ) : null}
          <div className="bg-white shadow-md px-8 pt-6 pb-8 mb-4 rounded-lg shadow-xl h-full flex items-center justify-center lg:px-12">
            {!props.error ? (
              <div className="w-full h-full flex items-center justify-center flex-col justify-between">
                <img
                  src={BrandLogo}
                  className="object-contain md:w-1/5 w-24 self-center"
                  alt="Logo FNA"
                />

                {activeStep ===
                  steps.map((e) => e.title).indexOf("Intro") + 1 ? (
                  <InitialInformation
                    currentStep={activeStep}
                    handleChange={onChangeUseReducer}
                    documentType={state.documentType}
                    dispatch={dispatch}
                    steps={steps}
                    handleOnClickNext={handleOnClickNext}
                  />
                ) : null}

                {activeStep ===
                  steps.map((e) => e.title).indexOf("País del documento") + 1 && (
                    <CountrySelector
                      dispatch={dispatch}
                      handleOnClickNext={handleOnClickNext}
                    />
                  )}

                {activeStep ===
                  steps.map((e) => e.title).indexOf("Tipo de Documento") + 1 && (
                    <DocumentType
                      currentStep={activeStep}
                      handleChange={onChangeUseReducer}
                      specificDocumentType={state.specificDocumentType}
                      dispatch={dispatch}
                      steps={steps}
                      handleOnClickNext={handleOnClickNext}
                    />
                  )}

                {activeStep ===
                  steps.map((e) => e.title).indexOf("Documento Frontal") + 1 && (
                    <FrontDocument
                      setFrontImageDoc={setFrontImageDoc}
                      currentStep={activeStep}
                      handleChange={onChangeUseReducer}
                      frontDocument={state.frontDocument}
                      steps={steps}
                      dispatch={dispatch}
                      handleOnClickNext={handleOnClickNext}
                    />
                  )}

                {activeStep ===
                  steps.map((e) => e.title).indexOf("Documento Posterior") + 1 && (
                    <BackDocument
                      currentStep={activeStep}
                      handleChange={onChangeUseReducer}
                      backDocument={state.backDocument}
                      steps={steps}
                      dispatch={dispatch}
                      handleOnClickNext={handleOnClickNext}
                    />
                  )}

                {activeStep ===
                  steps.map((e) => e.title).indexOf("Foto de validación") + 1 && (
                    <Selfie
                      dispatch={dispatch}
                      handleOnClickNext={handleOnClickNext}
                    />
                  )}

                {activeStep ===
                  steps.map((e) => e.title).indexOf("Video de validación") + 1 && (
                    deviceType === "desktop" ? (
                      <SelfieVideoDesktop
                        currentStep={activeStep}
                        handleChange={onChangeUseReducer}
                        selfieVideo={state.selfieVideo}
                        steps={steps}
                        dispatch={dispatch}
                        handleOnClickNext={handleOnClickNext}
                        setVideo={setVideo}
                      />
                    ) : (
                      <SelfieVideo
                        currentStep={activeStep}
                        handleChange={onChangeUseReducer}
                        selfieVideo={state.selfieVideo}
                        steps={steps}
                        dispatch={dispatch}
                        handleOnClickNext={handleOnClickNext}
                        setVideo={setVideo}
                      />
                    )
                  )}

                {activeStep ===
                  steps.map((e) => e.title).indexOf("Final") + 1 && (
                    <FinalStep
                      currentStep={activeStep}
                      video={video}
                      steps={steps}
                      state={state}
                      // handleSubmitAll={handleSubmitAll}
                      frontImageDoc={frontImageDoc}
                      userId={props.userId}
                      contractId={props.contractId}
                      specificDocumentType={props.specificDocumentType}
                      token={props.token}
                      canVideo={props.canVideo}
                    />
                  )}

                <Stepper
                  steps={steps}
                  activeStep={activeStep}
                  // onSelect={num => { handleOnClickStepper(num) }}
                  showNumber={false}
                />
              </div>
            ) : (
              <Fragment>
                {openFromIframe ? (
                  <button onClick={closePopUp} style={closeStylesError}>
                    {" "}
                    <img src={CloseSVG} alt="Close"></img>
                  </button>
                ) : null}
                <div>
                  <p>Validación expirada.</p>
                  <br></br>
                  {props.errorCountry ? <p>Código 1000</p> : null}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StepperForm;

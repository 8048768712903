import React, { Fragment } from "react";
import WebcamStreamCapture from "../WebStreamCapture";
import Liveness from '../../assets/images/liveness.gif';

const SelfieVideo = (props) => {
  return (
    <Fragment>
      <div className="w-full flex flex-col align-center justify-evenly flex-grow fade-in">
        <label
          className="block text-gray-700 text-sm font-bold mb-5"
        >
          Ahora tomaremos la grabación de tu rostro.
        </label>
        <label
          className="block text-gray-600 text-sm font-bold mb-0"
        >
          Presiona el botón azul en la parte inferior y gira tu cabeza en forma circular dos veces.
        </label>
        <img src={Liveness} alt="" className="object-contain w-full h-full lg:w-1/3 self-center" />
        <WebcamStreamCapture
          setVideo={props.setVideo}
          handleOnClickNext={props.handleOnClickNext}
          dispatch={props.dispatch}
        />
      </div>
    </Fragment>
  );
};

export default SelfieVideo;

import React, { Fragment } from "react";
import WebcamCapture from "../WebcamCapture";
import BackImg from '../../assets/images/back-document.png';

const BackDocument = (props) => {
  return (
    <Fragment>
      <div className="w-full flex flex-col align-center justify-evenly flex-grow fade-in">
        <h1 className="block text-gray-700 text-sm font-bold mb-2 mt-4 text-base md:text-lg lg:text-xl lg:mt-8 lg:mb-4">
          Carga la imagen de la parte posterior
        </h1>
        <img src={BackImg} alt="" className="object-contain w-1/2 h-1/2 lg:w-1/4 self-center" />
        <WebcamCapture content="Carga la imagen posterior" handleOnClickNext={props.handleOnClickNext} backDocument={true} dispatch={props.dispatch} />
      </div>
    </Fragment>
  );
};

export default BackDocument;

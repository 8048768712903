import React, { useState, useEffect } from "react";
import StepperForm from "./StepperForm";
import { getParams, sendMessage } from "../Utils";
import CloseSVG from "./../assets/images/close.svg";
import { countries, states } from "./arrays";

const closeStylesError = {
  maxWidth: "25px",
  position: "absolute",
  right: "4rem",
  top: "1rem",
  alignSelf: "flex-start",
};

const Form = () => {
  const [error, setError] = useState(false);
  const [errorCountry, setErrorCountry] = useState(false);
  const [canVideo, setCanVideo] = useState(false);
  const [contractId, setContractId] = useState(0);
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  const [country, setCountry] = useState("");
  const [stateReq, setState] = useState("");
  const [liveness, setLiveness] = useState(null);
  const [phoneNumberEnc, setPhoneNumberEnc] = useState();
  const [openFromIframe, setOpenFromIframe] = useState();

  var getContractById = function (id, token) {
    const URL_CONTRACT =
      "https://becomedigital-api.azurewebsites.net/api/v1/contract/" + id;
    fetch(URL_CONTRACT, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }).then((response) => {
      console.log(response);
      if (response.ok) {
        response.json().then((data) => {
          if (data.id) {
            setCanVideo(data.canSendVideo);
            setLiveness(data.liveness);
          } else {
            setError(true);
          }
        });
      } else {
        setError(true);
      }
    }).catch(e => {
      console.log(e)
      setError(true)
    });
  };

  useEffect(() => {
    window === window.parent
      ? setOpenFromIframe(false)
      : setOpenFromIframe(true);
    let params = getParams(window.location.href);
    // var size = Object.keys(params).length; // Not needed
    // Check if params have a accessToken key, and userId key and contractId key
    if (params.accessToken && params.userId && params.contractId) {
      getContractById(params.contractId, params.accessToken);
      setContractId(params.contractId);
      setUserId(params.userId);
      setToken(params.accessToken);
      if ("phoneNumberEnc" in params) {
        setPhoneNumberEnc(params.phoneNumberEnc);
      }

      if ("country" in params) {
        // countrys here
        if (countries.includes(params.country)) {
          setCountry(params.country);
          if ("state" in params) {
            // states here
            if (states.includes(params.state) && params.country === "US") {
              setState(params.state);
            } else {
              setError(true);
            }
          }
        } else {
          setError(true);
        }
      }
    } else {
      setErrorCountry(true);
      setError(true);
    }
  }, []);
  return (!error ?
    <StepperForm
      error={error}
      errorCountry={errorCountry}
      canVideo={canVideo}
      userId={userId}
      token={token}
      contractId={contractId}
      country={country}
      stateReq={stateReq}
      phoneNumberEnc={phoneNumberEnc}
      liveness={liveness}
    /> :
    <div>
      {openFromIframe ? (
        <button style={closeStylesError} onClick={(e) => {
          e.preventDefault();
          sendMessage("exitedSdk", { data: "exited manually by user" });
        }}>
          {" "}
          <img src={CloseSVG} alt="Close"></img>
        </button>
      ) : null}
      <div>
        <p>Faltan datos necesarios.</p>
        <p>Contacte a su administrador de sistema para más información.</p>
        <br></br>
        {errorCountry ? <p>Código 1000</p> : null}
      </div>
    </div>

  );
};

export default Form;

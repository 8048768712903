import React, { Fragment, useState, useEffect } from "react";

const DocumentType = ({ dispatch, handleOnClickNext }) => {
  const [documentType, setDocumentType] = useState("");

  useEffect(() => {
    if (documentType === 'passport') {
      dispatch({
        type: "documentType",
        value: documentType
      });
      dispatch({
        type: "specificDocumentType",
        value: "",
      });
    } else {
      dispatch({
        type: "specificDocumentType",
        value: documentType,
      });
      dispatch({
        type: "documentType",
        value: 'national-id'
      });
    }
    if(documentType) {
      handleOnClickNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentType]);


  const handleOnChange = (e) => {
    setDocumentType(e.target.value)
  }
  return (
    <Fragment>
      <div className="w-full flex flex-col align-center justify-evenly flex-grow fade-in">
        <h1 className="block text-fna font-bold text-base font-bold xl:mb-2 md:text-2xl lg:pt-2 xl:pt-4">
          Tipo de documento
        </h1>
        <div className="h-full w-full flex flex-col items-center justify-evenly xl:px-6">
          <input type="radio" name="documenttype" value="citizenCard" id="citizenCard" hidden onChange={handleOnChange} selected={documentType === "citizenCard" ? true : false} />
          <label htmlFor="citizenCard" className={`cursor-pointer border bg-white rounded-b rounded md:h-32 max-w-sm w-full lg:max-w-full lg:flex lg:items-center hover:shadow-md mt-2 ${documentType === 'citizenCard' ? 'shadow-lg border-blue-900' : 'border-gray-400'}`}>
            <div className="h-16 xl:h-auto xl:w-40 xl:h-20 flex-none rounded-t xl:rounded-t-none xl:rounded-l text-center overflow-hidden" title="Cedula de ciudadania">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-full self-center text-gray-700 xl:h-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
              </svg>
            </div>
            <div className="p-1 xl:p-4 w-full flex flex-col justify-between leading-normal">
              <div className="text-gray-800 font-bold text-base xl:mb-2" > Cédula de ciudadania</div >
              <p className="text-gray-700 text-base" > Documento nacional expedido por la registraduría nacional.</p >
            </div >
          </label >
          <input type="radio" name="documenttype" value="foreignCard" id="foreignCard" hidden onChange={handleOnChange} selected={documentType === "foreignCard" ? true : false} />
          <label htmlFor="foreignCard" className={`cursor-pointer border bg-white rounded-b rounded md:h-32 max-w-sm w-full lg:max-w-full lg:flex lg:items-center hover:shadow-md mt-2 ${documentType === 'foreignCard' ? 'shadow-lg border-blue-900' : 'border-gray-400'}`}>
            <div className="h-16 xl:h-auto xl:w-40 xl:h-20 flex-none rounded-t xl:rounded-t-none xl:rounded-l text-center overflow-hidden" title="Cedula de extranjería">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-full self-center text-gray-700 xl:h-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path d="m9.9511,5.9511l-5,0a2,2 0 0 0 -2,2l0,9a2,2 0 0 0 2,2l14,0a2,2 0 0 0 2,-2l0,-9a2,2 0 0 0 -2,-2l-5,0m-4,0l0,-1a2,2 0 1 1 4,0l0,1m-4,0a2,2 0 1 0 4,0m1,5l3,0m-3,4l2,0" strokeLinejoin="round" strokeLinecap="round" />
                <path strokeWidth="0.9" d="m5.75484,12.53219l0.58176,0a0.59821,0.59821 0 0 1 0.59821,0.59821l0,0.2991a0.59821,0.59821 0 0 0 0.59821,0.59821a0.59821,0.59821 0 0 1 0.59821,0.59821l0,0.88086m-0.89731,-5.08775l0,0.4681a0.74776,0.74776 0 0 0 0.74776,0.74776l0.14955,0a0.59821,0.59821 0 0 1 0.59821,0.59821a0.59821,0.59821 0 1 0 1.19641,0a0.59821,0.59821 0 0 1 0.59821,-0.59821l0.31825,0m-1.51466,3.7352l0,-0.74417a0.59821,0.59821 0 0 1 0.59821,-0.59821l0.91645,0m0.27996,-1.19641a2.69193,2.69193 0 1 1 -5.38386,0a2.69193,2.69193 0 0 1 5.38386,0z" strokeLinejoin="round" strokeLinecap="round" />
              </svg>
            </div>
            <div className="p-1 xl:p-4 w-full flex flex-col justify-between leading-normal">
              <div className="text-gray-800 font-bold text-base xl:mb-2" > Cédula de extranjería</div >
              <p className="text-gray-700 text-base" > Documento de extranjería expedido por la registraduría nacional.</p >
            </div >
          </label >
          <input type="radio" name="documenttype" value="passport" id="passport" hidden onChange={handleOnChange} selected={documentType === "passport" ? true : false} />
          <label htmlFor="passport" className={`cursor-pointer border bg-white rounded-b rounded md:h-32 max-w-sm w-full lg:max-w-full lg:flex lg:items-center hover:shadow-md mt-2 ${documentType === 'passport' ? 'shadow-lg border-blue-900' : 'border-gray-400'}`}>
            <div className="h-16 xl:h-auto xl:w-40 xl:h-20 flex-none rounded-t xl:rounded-t-none xl:rounded-l text-center overflow-hidden" title="Cedula de extranjería">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-full self-center text-gray-700 xl:h-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path d="m9.9511,5.9511l-5,0a2,2 0 0 0 -2,2l0,9a2,2 0 0 0 2,2l14,0a2,2 0 0 0 2,-2l0,-9a2,2 0 0 0 -2,-2l-5,0m-4,0l0,-1a2,2 0 1 1 4,0l0,1m-4,0a2,2 0 1 0 4,0m1,5l3,0m-3,4l2,0" strokeLinejoin="round" strokeLinecap="round" />
                <path strokeWidth="0.9" d="m5.75484,12.53219l0.58176,0a0.59821,0.59821 0 0 1 0.59821,0.59821l0,0.2991a0.59821,0.59821 0 0 0 0.59821,0.59821a0.59821,0.59821 0 0 1 0.59821,0.59821l0,0.88086m-0.89731,-5.08775l0,0.4681a0.74776,0.74776 0 0 0 0.74776,0.74776l0.14955,0a0.59821,0.59821 0 0 1 0.59821,0.59821a0.59821,0.59821 0 1 0 1.19641,0a0.59821,0.59821 0 0 1 0.59821,-0.59821l0.31825,0m-1.51466,3.7352l0,-0.74417a0.59821,0.59821 0 0 1 0.59821,-0.59821l0.91645,0m0.27996,-1.19641a2.69193,2.69193 0 1 1 -5.38386,0a2.69193,2.69193 0 0 1 5.38386,0z" strokeLinejoin="round" strokeLinecap="round" />
              </svg>
            </div>
            <div className="p-1 xl:p-4 w-full flex flex-col justify-between leading-normal">
              <div className="text-gray-800 font-bold text-base xl:mb-2" > Pasaporte</div >
              <p className="text-gray-700 text-base" > Pasaporte expedido en la gobernación o entidad competente.</p >
            </div >
          </label >
        </div >
      </div >

    </Fragment >
  );
};

export default DocumentType;

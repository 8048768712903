import React, { Fragment } from "react";
import DataProcessingPolicy from "../DataProcessingPolicy";

const InitialInformation = (props) => {

  // const handleChange = (value) => {
  //   console.log(value);
  //   props.dispatch({
  //     type: "documentType",
  //     value: value,
  //   });
  //   props.handleOnClickNext();
  // };

  return (
    <Fragment>
      <div className="w-full flex flex-col align-center justify-evenly flex-grow">
        <h1 className="block text-fna font-bold text-xl font-bold mb-2 md:text-2xl">Bienvenido</h1>
        <p className="text-left xl:text-justify text-base md:text-lg md:pl-8 text-gray-900 md:pr-8">
          Bienvenido al portal de validación de identidad, para continuar ten presente las siguientes recomendaciones:
        </p>
        <ul className="md:text-base md:text-lg pl-4 md:pl-12 md:pr-8 mt-4 self-center list-decimal text-left xl:text-justify text-gray-900">
          <li className="text-base md:text-lg">Si accediste desde un computador, asegúrate de tener tu documento escaneado en ambas caras y por separado.</li>
          <li className="text-base md:text-lg">Si accediste desde un dispositivo móvil o celular, ten tu documento a la mano.</li>
          <li className="text-base md:text-lg">Realizaremos una captura en video de tu rostro.</li>
          
        </ul>
        <p className="mt-4 text-left xl:text-justify text-base md:text-lg md:pl-8 text-gray-900">
          <strong>Nota:</strong> Al tomar la foto o video asegúrate de lograr captar una imagen con la mayor nitidez posible, apoyándote sobre un lugar sólido para mantener el equilibrio y evita utilizar zoom o flash.
          <br /> Esta validación aplica para Cédula de Ciudadanía, Cédula de Extranjería y Pasaporte.
        </p>
        <DataProcessingPolicy />
        <input
          className="fna-color w-40 self-center py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline cursor-pointer"
          type="button"
          value="Siguiente"
          onClick={() => { props.handleOnClickNext() }}
        />
      </div>
    </Fragment>
  );
};

export default InitialInformation;